<template>
  <div>
    <div
      v-if="event.id&&!event.singleSession"
      class="text-right mb-1"
    >
      <b-button
        variant="primary"
        @click="redirectToSessionsList"
      >
        Manage Sessions
      </b-button>
    </div>

    <b-card>
      <spinner-loader :loading="loading" />

      <b-tabs>
        <!-- Event -->
        <b-tab>
          <template #title>
            <span>Event</span>
          </template>

          <template v-if="!loading">
            <single-session-event-form
              v-if="event.singleSession"
              :event="event"
              :disabled="!canUserEditPublishedSession"
              :session="session"
              :submit="submitSingleSessionEvent"
            />

            <event-form
              v-else
              :submit="editEvent"
              :event.sync="event"
            />
          </template>
        </b-tab>

        <upload-progress
          :progress="progress"
          class="mt-2"
        />

        <!-- Fundraising Event -->
        <crowd-fundraising-form
          v-if="event.fundRaisingProjectId"
          :submit-functions="{
            submitCrowdFund:editCrowdFundMainInfo,
            addCrowdFundLocation,
            addCrowdFundPlan,
            addCrowdFundMedia,
          }"
          :crowdfund.sync="crowdfund"
        />
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import EventForm from '@/common/components/QuickEvents/EventForm.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'
import CrowdFundraisingForm from '@/common/components/Fundraising/CrowdFundraising/CrowdFundraisingForm.vue'
import QuickEvent from '@/common/compositions/QuickEvents/quickEventApi'
import Session from '@/common/compositions/QuickEvents/sessionApi'
import SingleSessionEventForm from '@/common/components/QuickEvents/SingleSessionEventForm.vue'

export default {
  name: 'EditQuickEvent',
  components: {
    EventForm,
    UploadProgress,
    SpinnerLoader,
    CrowdFundraisingForm,
    SingleSessionEventForm,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    canUserEditPublishedSession() {
      return this.$moment().isBefore(this.session.start_time)
      || ((this.$moment().isAfter(this.session.start_time) && this.$can('updatePublished', 'EventController')))
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      editCrowdFundMainInfo, addCrowdFundLocation, addCrowdFundMedia, addCrowdFundPlan, crowdfund, getFundraisingProject, loadingFundraising,
    } = FundraisingApi()

    const {
      getEventRequest, event, progress, editEventRequest,
    } = QuickEvent()

    const { session, editSessionRequest } = Session()

    return {
      successfulOperationAlert,
      editCrowdFundMainInfo,
      addCrowdFundLocation,
      addCrowdFundMedia,
      addCrowdFundPlan,
      crowdfund,
      getFundraisingProject,
      loadingFundraising,
      getEventRequest,
      event,
      progress,
      editEventRequest,
      session,
      editSessionRequest,
    }
  },
  created() {
    this.getEvent().then(() => {
      if (this.event.fundRaisingProjectId) { this.getFundraisingProject(this.event.fundRaisingProjectId) }

      if (this.event.singleSession) {
        this.setSession()
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitSingleSessionEvent() {
      this.$set(this.session, 'start_time', this.$moment(this.event.startTime).format('YYYY-MM-DD HH:mm'))
      this.$set(this.session, 'end_time', this.$moment(this.event.endTime).format('YYYY-MM-DD HH:mm'))
      return this.editEvent(this.event.id).then(() => this.editSessionRequest(this.event.id, this.session.id))
    },
    getEvent() {
      return this.getEventRequest(this.$route.params.id).then(res => {
        this.event = {
          ...res.data.data,
          location: { ...res.data.data.location },
          image: [res.data.data.image],
          brochure: [res.data.data.brochure],
        }
      })
    },
    editEvent() {
      return this.editEventRequest(this.$route.params.id).then(() => {
        this.successfulOperationAlert('Event is updated successfully')
        this.$router.push({ name: 'quick-events-list' })
      })
    },
    setSession() {
      const session = this.event.sessions[0]

      this.session = {
        ...session,
        language: session.language_id,
        paidTicket: {
          paid: session.paid || 'free',
          price: session.price,
          tax_ratio: session.tax_ratio,
          charity_deduction_type: session.charity_deduction_type,
          charity_deduction_amount: session.charity_deduction_amount,
          donation_deduction_type: session.donation_deduction_type,
          donation_deduction_amount: session.donation_deduction_amount,
          payment_method: session.payment_method,
          additional_services: session.price ? session.additional_services : [],
          user_covers_comissions: session.user_covers_comissions,
          charity_id: session.charity_id,
        },
      }
    },
    redirectToSessionsList() {
      this.$router.push({ name: 'quick-event-sessions-list', params: { eventId: this.event.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
